.App {
  text-align: left;
  background-color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pagination>li>a {
  background-color: white;
  color: #000;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination>.active>a {
  color: white;
  background-color: #000 !Important;
  border: solid 1px #000 !Important;
}

.pagination>.active>a:hover {
  color: #fff;
  background-color: #424649 !Important;
  border: solid 1px #373b3e;
}

.navbar {
  background-color: #fff !important;
}

.navbar ul li a {
  padding: 8px;
}

.navbar ul li a {
  background-color: #fff !important;
  border: 0;
}

.navbar ul li a:hover {
  background-color: #d3d4d5 !important;
}

.card-header {
  background-color: #fff !important;
}